import Banner from "../components/Banner/Banner";
import Nav from "../components/menu/Nav";
import armyImage from "../assets/images/army.jpg";
import air from "../assets/images/airforce.jpg";
import marine from "../assets/images/marine.jpg";
import navy from "../assets/images/navy.jpg";
import { useState } from "react";
import DonateButton from "../sections/donatePayment";
import Modal from "../components/modal/modal";
import { NavLink } from "react-router-dom";

export default function Donate() {
  const [selectItem, setSelectItem] = useState(0);
  const [army, setArmy] = useState("");
  const [error, setError] = useState("");
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [refCode, setRefCode] = useState("");

  const handlerArmy = (name) => {
    if (army === name) {
      setArmy("");
    } else {
      setArmy(name);
    }
  };

  function handelSelect(num) {
    if (num === selectItem) {
      setSelectItem(0);
    } else {
      setSelectItem(num);
    }
  }

  const title = (
    <h1 className="text-title-md text-center mb-12 mt-2">How It works</h1>
  );

  //   Content

  const content = (
    <ul className="flex flex-col justify-center items-start gap-6 px-10 text-lg font-medium mb-8">
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        1 - Select Your Branch
      </li>
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        2 - Select a donation amount or input a custom amount
      </li>
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        3 - Input your name and email
      </li>
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        4 - Pay the donation
      </li>
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        5 - Receive a unique art work in your inbox
      </li>
      <li className="flex justify-start items-center gap-3">
        <span className="block bg-blue-500 w-3.5 h-3.5 rounded-full shadow-md"></span>
        6 - Every 2500 sold will initiate a drawing for 10 veterans to receive
        $10,000.00 gift card each
      </li>
    </ul>
  );

  // Actions

  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen((prevState) => !prevState);

  return (
    <>
      <Nav />
      <Banner />
      <section className="py-16">
        <div>
          <div className="w-full flex justify-center items-center mb-14">
            <button
              onClick={toggleModal}
              className={`font-semibold border-2 border-white rounded-lg px-3 py-2 text-white`}>
              How It Works
            </button>
          </div>

          <Modal
            open={open}
            toggleModal={toggleModal}
            title={title}
            content={content}
            actions={null}
          />

          <p className="text-center text-white font-semibold text-2xl">
            Select Your Branch
          </p>
          <div>
            <div className="py-16">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                <div
                  onClick={() => {
                    handelSelect(1);
                    handlerArmy("Airforce");
                  }}
                  className="group flex flex-col justify-center items-center gap-6">
                  <h4 className="text-white text-2xl text-center font-medium">
                    Airforce
                  </h4>
                  <img
                    style={{
                      border: selectItem === 1 ? "4px solid #4ade80" : "",
                    }}
                    src={air}
                    alt="solider"
                    className="rounded-full p-4 group-hover:border-4 group-hover:border-green-400 duration-100 cursor-pointer"
                  />
                  <button
                    onClick={() => handlerArmy("Airforce")}
                    className={`${
                      army === "airforce" || selectItem === 1
                        ? "bg-white/30"
                        : "bg-transparent"
                    } border-2 font-semibold border-white rounded-lg text-white px-3 py-2`}>
                    Airforce
                  </button>
                </div>
                <div
                  onClick={() => {
                    handelSelect(2);
                    handlerArmy("Army");
                  }}
                  className="group flex flex-col justify-center items-center gap-6">
                  <h4 className="text-white text-2xl text-center font-medium">
                    Army
                  </h4>
                  <img
                    style={{
                      border: selectItem === 2 ? "4px solid #4ade80" : "",
                    }}
                    src={armyImage}
                    alt="solider"
                    className="rounded-full p-4 group-hover:border-4 group-hover:border-green-400 duration-100 cursor-pointer"
                  />

                  <button
                    style={{ background: army === "Army" ? "#5a7c82" : "" }}
                    onClick={() => handlerArmy("Army")}
                    className={`font-semibold border-2 border-white rounded-lg px-3 py-2 text-white`}>
                    Army
                  </button>
                </div>
                <div
                  onClick={() => {
                    handelSelect(3);
                    handlerArmy("Navy");
                  }}
                  className="group flex flex-col justify-center items-center gap-6">
                  <h4 className="text-white text-2xl text-center font-medium">
                    Navy
                  </h4>
                  <img
                    style={{
                      border: selectItem === 3 ? "4px solid #4ade80" : "",
                    }}
                    src={navy}
                    alt="solider"
                    className="rounded-full p-4 group-hover:border-4 group-hover:border-green-400 duration-100 cursor-pointer"
                  />
                  <button
                    style={{ background: army === "Navy" ? "#5a7c82" : "" }}
                    onClick={() => handlerArmy("Navy")}
                    className={`font-semibold text-white border-2 border-white rounded-lg py-2 px-3`}>
                    Navy
                  </button>
                </div>
                <div
                  onClick={() => {
                    handelSelect(4);
                    handlerArmy("Marines");
                  }}
                  className="group flex flex-col justify-center items-center gap-6">
                  <h4 className="text-white text-2xl text-center font-medium">
                    Marine
                  </h4>
                  <img
                    style={{
                      border: selectItem === 4 ? "4px solid #4ade80" : "",
                    }}
                    src={marine}
                    alt="solider"
                    className="rounded-full p-4 group-hover:border-4 group-hover:border-green-400 duration-100 cursor-pointer"
                  />
                  <button
                    onClick={() => handlerArmy("Marines")}
                    className={`${
                      army === "Marines" || selectItem === 4
                        ? "bg-white/30"
                        : ""
                    } font-semibold text-white border-2 border-white rounded-lg px-3 py-2`}>
                    Marine
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div>
            <form className="grid w-full md:w-[60%] mx-auto gap-10 px-5 py-16">
              <div className="gird justify-center">
                <h4 className="text-white text-4xl font-medium text-center mb-8">
                  Donation Amount
                </h4>
                <div className="text-white grid grid-cols-2 sm:flex gap-16 py-5 w-fit mx-auto">
                  <span
                    onClick={() => setAmount(10)}
                    className={`text-5xl border-2 rounded-lg font-medium 
                    ${amount === 10 ? "bg-white/30" : ""}
                    hover:bg-white/30 cursor-pointer border-white px-7 py-3`}>
                    $10
                  </span>

                  <span
                    onClick={() => setAmount(25)}
                    className={`text-5xl border-2 rounded-lg font-medium 
                    ${amount === 25 ? "bg-white/30" : ""}
                    hover:bg-white/30 cursor-pointer border-white px-7 py-3`}>
                    $25
                  </span>

                  <span
                    onClick={() => setAmount(150)}
                    className={`text-5xl border-2 rounded-lg font-medium ${
                      amount === 150 ? "bg-white/30" : ""
                    } hover:bg-white/30 cursor-pointer border-white px-7 py-3`}>
                    $150
                  </span>
                </div>
              </div>

              <div className="mb-10 mx-auto">
                <input
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    const number = Number(e.target.value);
                    if (!isNaN(number) && Number.isInteger(number)) {
                      setAmount(number);
                      setError("");
                    } else {
                      setError("Please enter a positive integer.");
                    }
                  }}
                  placeholder="Enter custom donate"
                  className="rounded-lg px-4 py-2 text-xl bg-black/30 mx-auto text-white"
                />
                {error !== "" ? error : ""}
              </div>
              <div className="grid md:flex md:justify-center gap-10">
                <input
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="bg-black/20 p-5 text-white rounded-md w-full h-14 text-xl"
                  placeholder="Name"
                />
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="bg-black/20 p-5 text-white rounded-md w-full h-14 text-xl"
                  placeholder="Last Name"
                />
              </div>
              <div className="grid md:flex md:justify-center gap-10">
                <div className="w-full">
                  <span className="text-red-600 ml-2">*</span>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-black/20 p-5 rounded-md w-full h-14 text-xl text-white"
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="w-full mt-6">
                  <input
                    onChange={(e) => setRefCode(e.target.value)}
                    type="text"
                    className="bg-black/20 p-5 text-white rounded-md w-full h-14 text-xl"
                    placeholder="Referral Code"
                  />
                </div>
              </div>
              <div></div>

              <div className="flex items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
                />
                <label className="ms-2 text-sm font-medium text-gray-50">
                  agree to{" "}
                  <NavLink
                    to={"/terms-conditions"}
                    className="text-blue-400 font-normal cursor-pointer">
                    terms and conditions
                  </NavLink>
                </label>
              </div>

              <DonateButton
                amount={amount}
                email={email}
                firstName={firstName}
                lastName={lastName}
                army={army}
                error={error}
                setAmount={setAmount}
                refCode={refCode}
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
