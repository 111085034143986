import logo from "../../assets/images/humv-logo.png";
import insta from "../../assets/images/insta.webp";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Footer() {
  const [email, setEmail] = useState("");

  const sendEmail = async () => {
    return;
    // eslint-disable-next-line no-unreachable
    if (email.length > 1 && email.includes("@")) {
      const options = {
        method: "POST",
      };
      console.log({ email });
      const response = await fetch(
        `http://thehumv.pythonanywhere.com/news?to=${email}`,
        options
      );

      console.log(response);

      const data = await response.json();

      console.log(data);
    }
  };

  return (
    <footer className="bg-black/50 py-20">
      <div className="grid xl:flex items-start gap-10 max-w-[1500px] mx-auto">
        <div className="w-full px-5">
          <div className="grid xl:flex w-[80%] xl:mx-0  mx-auto">
            <img className="w-52 h-52 mx-auto" src={logo} alt="logo" />
            <p className="text-white text-lg mt-10 text-center xl:text-start">
              H.U.M.V. stands as a steadfast ally for military families. Our
              future goals are to offer a diverse range of services from
              financial counseling to mental health support, ensuring they never
              face their challenges alone. With unwavering dedication, H.U.M.V.
              embodies the spirit of unity and gratitude for our nation's
              heroes.
            </p>
          </div>
          <div className="flex gap-5 py-6 justify-center">
            <img
              className="bg-zinc-700 rounded-full p-3"
              src={insta}
              alt="discord icon"
            />
          </div>
        </div>
        <div className="hidden xl:grid w-fit mx-auto xl:mx-0 xl:w-[200px]">
          <h2 className="uppercase text-xl font-medium text-green-400 my-5 text-center xl:text-start">
            pages
          </h2>
          <ul className="text-white text-lg grid gap-5 uppercase text-center xl:text-start">
            <li className="hover:text-green-400 duration-200  xl:w-fit">
              <Link to="/">home</Link>
            </li>
            <li className="hover:text-green-400 duration-200 xl:w-fit">
              <Link to="/donate">donate</Link>
            </li>
            <li className="hover:text-green-400 duration-200 xl:w-fit">
              <Link to="/about-us">about us</Link>
            </li>
            <li className="hover:text-green-400 duration-200 xl:w-fit">
              <Link>contact us</Link>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-[600px] hidden xl:block mx-auto xl:mx-0">
          <h2 className="uppercase text-xl font-medium text-green-400 my-5 text-center xl:text-start">
            newsletter
          </h2>
          <p className="text-white text-lg text-center xl:text-start">
            Join our military family newsletter to receive essential updates,
            valuable tips, and inspiring stories. Stay connected with a
            community that understands the unique challenges military families
            face. Subscribe now and be a part of our supportive network.
          </p>
          <div className="py-5">
            <div className="w-fit xl:w-full xl:mx-0 mx-auto">
              <form
                action={`https://thehumv.pythonanywhere.com/news?to=${email}`}
                method="POST">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="emali"
                  placeholder="Your email address"
                  className="bg-black/50 p-5 h-14 text-white text-lg w-2/3"
                />
                <button
                  type="submit"
                  onClick={sendEmail}
                  className="w-fit px-8 py-3 bg-green-400 text-white hover:bg-green-800 transition-all duration-300 font-medium text-lg">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="grid lg:flex xl:hidden">
          <div className="grid w-fit mx-auto xl:mx-0 xl:w-[200px] pb-20">
            <h2 className="uppercase text-xl font-medium text-green-400 my-5 text-center xl:text-start">
              pages
            </h2>
            <ul className="text-white text-lg grid gap-5 uppercase text-center xl:text-start">
              <li className="hover:text-green-400 duration-200  xl:w-fit">
                <Link to="/">home</Link>
              </li>
              <li className="hover:text-green-400 duration-200 xl:w-fit">
                <Link to="/donate">donate</Link>
              </li>
              <li className="hover:text-green-400 duration-200 xl:w-fit">
                <Link to="/about-us">about us</Link>
              </li>
              <li className="hover:text-green-400 duration-200 xl:w-fit">
                <Link>contact us</Link>
              </li>
            </ul>
          </div>
          <div className="w-[90%] md:w-[600px] mx-auto xl:mx-0">
            <h2 className="uppercase text-xl font-medium text-green-400 my-5 text-center xl:text-start">
              newsletter
            </h2>
            <p className="text-white text-lg text-center xl:text-start">
              Join our military family newsletter to receive essential updates,
              valuable tips, and inspiring stories. Stay connected with a
              community that understands the unique challenges military families
              face. Subscribe now and be a part of our supportive network.
            </p>
            <div className="py-5">
              <div className="w-fit xl:w-full xl:mx-0 mx-auto">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="emali"
                  placeholder="Your email address"
                  className="bg-black/50 p-5 h-14 text-white text-lg w-2/3"
                />
                <button
                  onClick={sendEmail}
                  className="w-fit px-8 py-3 bg-green-400 text-white hover:bg-green-800 transition-all duration-300 font-medium text-lg">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
