import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51O7zAtAJ4JF9u9cDnfMRLc7nXvUcpBQdev6oIJ175EWRQE5wVWuN5fdkwDCzI8Rzq3xRBCviqVULyHSgFSFeBOzd00tns93jFj"
);

const DonateButton = ({
  amount,
  setAmount,
  email,
  army,
  firstName,
  lastName,
  refCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();

    setLoading(true);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [
        {
          price: "price_1OgnxsAJ4JF9u9cDvBMDSf5U",
          quantity: amount ? amount : 1,
        },
      ],
      successUrl: `https://thehumv.pythonanywhere.com/image?email=${email}&flag=${army}&name=${firstName}&last_name=${lastName}&amount=${amount}&ref_code=${refCode}`,
    });

    if (error) {
      console.error(error);
      setLoading(false);
      setError("something wrong please try a another time");
    }
    setAmount(null);
  };
  const isDisabled =
    !loading &&
    amount !== 0 &&
    amount !== undefined &&
    army !== "" &&
    email !== "";

  return (
    <>
      <button
        disabled={!isDisabled}
        className={` w-fit mx-auto text-white font-semibold text-lg px-3 py-2 rounded-lg ${
          !isDisabled
            ? "bg-gray-500 cursor-auto"
            : "bg-green-400 cursor-pointer"
        } cursor-pointer`}
        onClick={handleClick}>
        Donate
      </button>
      {error !== "" ? error : ""}
    </>
  );
};

export default DonateButton;
