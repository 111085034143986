import React from "react";

export default function Fade(props) {
  const { condition, children } = props;

  return (
    <div
      className={`${
        condition ? "visible opacity-100" : "invisible opacity-0"
      } ease-in-out duration-200`}>
      {children}
    </div>
  );
}
