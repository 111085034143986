import video from "../../assets/video.mp4";

export default function LeftSideBar() {
  return (
    <aside className="grid gap-10 overflow-y-auto w-fit px-5 py-10 mx-auto xl:w-[800px] scroll-bar text-starttext-white h-[1000px] xl:h-[700px] bg-zinc-800/50 rounded-lg">
      <div>
        <video width="100%" height="100%" controls className="rounded-lg">
          <source src={video} type="video/mp4" />
        </video>
        <p className="text-white mt-4">
          Welcome to H.U.M.V. here is a message from one of our founders
        </p>
      </div>
    </aside>
  );
}
