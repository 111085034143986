import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-16">
      <h1 className="text-title-lg text-white mb-10">Terms And Conditions</h1>

      <p className="text-white leading-10">
        NOTICE REGARDING DISPUTE RESOLUTION: These Terms of Use contain a
        binding arbitration provision and class action waiver that govern how
        claims will be resolved between you and H.U.M.V., LLC (together with our
        affiliates, “H.U.M.V. “we,” or “us”). if applicable, or where prohibited
        by law, by using the Services (as defined below), you agree that
        disputes between you and H.U.M.V. will be resolved through binding,
        individual arbitration (instead of going to court before a judge and
        jury) and you hereby waive your right to participate in a class action
        lawsuit or class-⁠wide arbitration. * * * Please read these Terms
        carefully and check them regularly as they may change from time to time,
        per Section 3, below. 1. APPLICABILITY: These Terms of Use (these
        “Terms”) are between you and H.U.M.V., LLC (together with our
        affiliates, “H.U.M.V.” “we” or “us”) and provide the general terms and
        conditions that govern your access to, use of, and interactions with:
        (A) the H.U.M.V. website located at www.thehumv.com (the “Website”), (B)
        H.U.M.V. applications made available through the Website (the
        “Software”), (C) any H.U.M.V. -controlled social media pages (including
        on Facebook, Instagram, Spotify and Twitter), and/or (D) any content,
        functionality, and services offered on or through the Website, Software
        and social media pages. To make these Terms easier to read, the Website
        and Software, along with the H.U.M.V. -controlled social media pages and
        any content, functionality, and services offered on or through the
        Website, Software and social media pages, are collectively called the
        “Services.” 2. YOUR ACCEPTANCE OF THESE TERMS: Your use of the Services
        in any way signifies that you accept and agree to these Terms, including
        any modifications that H.U.M.V. makes from time to time. If you do not
        wish to be bound by these Terms, you may not access or use the Service.
        3. CHANGES TO THESE TERMS: H.U.M.V. may modify these Terms at any time,
        in our sole discretion. To the extent permitted by law, all changes are
        effective immediately when we post them and apply to all access to and
        use of the Services thereafter. Your continued use of the Services
        following the posting of revised Terms means that you accept and agree
        to the changes. You are responsible for checking these Terms regularly
        to ensure that you understand the Terms that apply at the time of your
        access to or use of the Services. 4. AGE REQUIREMENT: You must be at
        least 18 years old, or the age of legal majority in your jurisdiction of
        residence, to use the Services. 5. ACCESS TO THE SERVICES: The Services
        are offered only for your personal, non-commercial use, and not for the
        use or benefit of any third-party. We may, in our sole discretion,
        change, modify and/or refuse to offer some of all the Services to any
        person and change the eligibility criteria at any time. Any right to
        access and use the Services is automatically revoked where these Terms,
        or use of the Services, is prohibited or conflicts with any applicable
        law, rule, or regulation. Limiting or Modifying the Services: We reserve
        the right to withdraw, limit or otherwise modify the Services, in our
        sole discretion without notice. We will not be liable if for any reason
        all or any portion of the Services are unavailable at any time or for
        any period. Internet Access: You are solely responsible for making all
        arrangements necessary for you to have access to the Services,
        including, without limitation, securing internet connections, and for
        any telecommunication fees. 6. Account Registration and Security: To
        enjoy full access and use of our Services, you may be asked to provide
        certain registration details or other information so to create a user
        account (an “Account”). Account Information: It is a condition of your
        use of the Services that all the information you provide is correct,
        current, and complete. You may not use someone else’s name, or any name,
        location, other public profile information or image that violates any
        third-party rights, is against the law, or that is offensive, obscene,
        or otherwise objectionable. You agree to promptly notify us if any of
        your information changes or is inaccurate. Account Security: Your
        account is for your personal use only. You may not allow others to use
        your Account and may not or transfer your Account to any other person or
        entity. You must safeguard the confidentiality of your password, and if
        you are using a device that others have access to, log out of your
        account after using the Services. You are responsible for all activity
        that occurs under your account, including any activity by unauthorized
        users. If you suspect or know of unauthorized access to your account,
        change your password and immediately notify H.U.M.V. by sending an email
        to info@thehumv.com. 7. TERMINATION OF RIGHT TO USE; ACCOUNT
        DELETION: We have the right to suspend, delete and/or disable any
        Account (or any part thereof) or block or remove any User Contribution
        for any lawful reason, including if we determine that you have violated
        these Terms or that your conduct or User Contribution would tend to
        damage H.U.M.V.’s reputation or goodwill. If H.U.M.V. deletes your
        account or terminates your permission to use the Services, you may not
        re-register for or continue to use the Service under any other email or
        Account without explicit permission by H.U.M.V.. 8. LIMITED PERMISSION
        TO USE THE SERVICES: Limited License: Subject to your compliance with
        these Terms and solely for so long as you are permitted by us to access
        and use the Services, H.U.M.V. grants to you a revocable, limited,
        non-exclusive, non-transferable, non-sublicensable, non-assignable
        license to use the Services for your personal, non-commercial use only.
        You may not copy, modify, reverse engineer, disassemble, or create
        derivative works based on the Services, distribute, sell, grant rights
        in or to the Services, publicly display, republish, download, store, or
        transmit any of the Services, except as follows: Your computer may
        temporarily store copies of such materials in RAM incidental to your
        accessing and using the Services. You may store files that are
        automatically cached by your web browser for display enhancement
        purposes. You may print or download one copy of a reasonable number of
        pages of the Website for your own personal, non-commercial use and not
        for further reproduction, publication, or distribution. If we provide
        desktop, mobile, or other applications for download, you may download a
        single copy to your computer or mobile device solely for your own
        personal, non-commercial use, provided you agree to be bound by our end
        user license agreement for such applications. If we provide social media
        features with certain content, you may take such actions as are enabled
        by such features. This license will remain in effect unless and until
        you violate these Terms, or this license is terminated by you or
        H.U.M.V.. Any use of the Services not expressly permitted by these Terms
        is a breach of these Terms and may violate copyright, trademark, and
        other laws. H.U.M.V., in its sole discretion, may at any time for any
        reason suspend or terminate any license hereunder without prior notice.
        H.U.M.V. Intellectual Property: The interfaces, content, arrangement and
        layout of the Website including, but not limited to, the H.U.M.V.
        trademark and logo, designs, text, artwork, graphics, images, buttons,
        user interfaces, information, and other content, and any compilation of
        the foregoing (“H.U.M.V. Intellectual Property”) are the property of
        H.U.M.V., except where otherwise noted, and are protected from copying,
        imitation, communication, or simulation under United States and
        international laws and may not be reproduced, modified, communicated,
        displayed, distributed, or transmitted without the prior written
        permission of H.U.M.V.. All modifications and enhancements to the
        Services remain the sole property of H.U.M.V. You understand and
        acknowledge that, by using the Services, you do not acquire or obtain by
        implication or otherwise, any license or right to use any of the
        H.U.M.V. Intellectual Property in any manner not expressly permitted
        under these Terms. 9. GENERAL PROHIBITIONS AND H.U.M.V.’s ENFORCEMENT
        RIGHTS: You may use the Services only for lawful purposes and in
        accordance with these Terms. Additionally, you agree that you will not
        do any of the following: Impersonate or misrepresent your affiliation
        with any person or entity. Interfere, or attempt to interfere, with the
        proper working of the Services. Use the Services, or any portion
        thereof, for any commercial purpose or for the benefit of any
        third-party or in any manner not permitted by these Terms or permitted
        expressly in writing by H.U.M.V. Attempt to decipher, decompile,
        disassemble, or reverse engineer any of the software used to provide the
        Services. Access, tamper with, or use non-public areas of the Services,
        H.U.M.V.’s computer systems, or the technical delivery systems of
        H.U.M.V.’s providers. Attempt to probe, scan or test the vulnerability
        of any H.U.M.V. system or network or breach any security or
        authentication measures. Avoid, bypass, remove, deactivate, impair,
        descramble or otherwise circumvent any technological measure implemented
        by H.U.M.V. or any of H.U.M.V.’s providers or any other third-party
        (including another user) to protect the Services, systems, or other
        content. Bypass any territorial restrictions, including IP address-based
        restrictions, that may be applied to the Services. Use the Services in
        any manner that could disable, overburden, damage, or impair the Website
        or interfere with any other party's use of the Services, including their
        ability to engage in real time activities through the Website. Use any
        robot, spider, or other automatic device, process, or means to access
        the Services for any purpose, including monitoring or copying any of any
        information or materials. Use any manual process to access, monitor or
        copy any information or materials, or for any other purpose not
        expressly authorized in these Terms, without our prior written consent.
        Collect or store any personally identifiable information from other
        users of the Services. Use any device, software, or routine that
        interferes with the proper working of the Services. Introduce any
        viruses, Trojan horses, worms, logic bombs, or other material that is
        malicious or technologically harmful. Attempt to gain unauthorized
        access to, interfere with, damage, or disrupt any parts of the Website
        of Software, the servers on which the Website of Software are stored, or
        any other server, computer, or database connected to the Services.
        Attack the Website, or any other server, computer, or database connected
        to the Services, via a denial-of-service attack or a distributed
        denial-of-service attack. Use, display, mirror or frame the Services or
        any individual element within the Services, H.U.M.V.’s name, any
        H.U.M.V. trademark, logo or other proprietary information, or the layout
        and design of any page or copy contained on a page, without H.U.M.V.’s
        express written consent. Use any meta tags or other hidden text or
        metadata utilizing a H.U.M.V. trademark, logo URL or product name
        without H.U.M.V.’s express written consent. Forge any TCP/IP packet
        header or any part of the header information in any email or newsgroup
        posting, or in any way use the Services or User Contributions to send
        altered, deceptive, or false source-identifying information. Send any
        unsolicited or unauthorized advertising, promotional materials, email,
        junk mail, spam, chain letters or other form of solicitation. Post,
        upload, publish, submit, or transmit any User Contribution or engage in
        any activity that: infringes, misappropriates, or violates a
        third-party’s patent, copyright, trademark, trade secret, moral rights
        or other intellectual property rights, or rights of publicity or
        privacy. violates, or encourages any conduct that would violate, any
        applicable law or regulation or would give rise to civil liability. is
        fraudulent, false, misleading, or deceptive. is defamatory, obscene,
        pornographic, vulgar or offensive. promotes discrimination, bigotry,
        racism, hatred, harassment or harm against any individual or group. is
        violent or threatening or promotes violence or actions that are
        threatening to any person, animal, or entity. publishes the
        confidential, proprietary or personal information of any other person or
        entity exploits minors. promotes illegal or harmful activities or
        substances. Encourage or enable any other individual to do any of the
        foregoing to otherwise violate these Terms. Although we’re not obligated
        to monitor access to or use of the Services, we have the right to do so
        for the purpose of operating the Services, to ensure compliance with
        these Terms, to comply with applicable law or other legal requirements
        and to maintain the integrity and reputation of the Services and
        H.U.M.V.’s systems. We have the right to investigate violations of these
        Terms or conduct that affects the Services. We may also consult and
        cooperate with law enforcement authorities to prosecute users who
        violate the law. 11. USER CONTRIBUTIONS: The Services may include
        platforms, boards, profiles, forums, chat features and/or other
        interactive features (collectively, the “Interactive Services”) that
        allow users to post, submit, publish, display, or transmit to H.U.M.V.,
        to other users or to other persons generally (hereinafter, “post”)
        content or materials through or in connection with the use of any of our
        Services (collectively, “User Contributions”). We do guarantee that
        other users or third-parties will not use the User Contributions that
        you post. If you have ideas and/or information that you want to remain
        confidential and/or do not want us or others to use, do not post them.
        We shall have no responsibility to evaluate, use or compensate you for
        any User Contribution. Further, we are under no obligation to oversee,
        monitor, or moderate any Interactive Service we provide. You may be able
        to remove your User Contributions by specifically deleting it, but we
        are not responsible or liable for the removal or deletion of (or the
        failure to remove or delete) any of your User Contributions.
        Non-Confidential and Non-Proprietary: Any User Contribution, whether
        publicly posted or privately transmitted, will be considered
        non-confidential and non-proprietary. License to Use User
        Contributions: You explicitly grant H.U.M.V. and its service providers,
        and each of their and our respective licensees, successors, and assigns
        a worldwide, perpetual, irrevocable, non-exclusive, sublicensable
        (through multiple tiers), transferable, royalty-free license and right
        to use, copy, transmit, distribute, publicly perform and display
        (through all media now known or later created), edit, modify, and make
        derivative works from your User Contribution (including, without
        limitation, translations) for any purpose whatsoever, commercial or
        otherwise, without any attribution and/or compensation to you. In
        addition, you waive any so-called “moral rights” or rights of privacy or
        publicity in your User Contribution(s). You further grant all users of
        the Services permission to view your User Contribution(s) for their
        personal, non-commercial purposes. If you make suggestions to H.U.M.V.
        or through the Services about improving or adding new features or
        products to the Services or you otherwise provide feedback, product or
        service reviews or testimonials, you hereby grant to H.U.M.V. a
        worldwide, perpetual, irrevocable, non-exclusive, sublicensable (through
        multiple tiers), transferable royalty-free license and right to use,
        copy, modify, create derivative works based upon and otherwise exploit
        your suggestions, feedback, reviews and testimonials for any purpose
        (including for marketing), without any notice, compensation or other
        obligation to you. Your Responsibility for Your User Contributions: Any
        User Contribution, whether publicly posted or privately transmitted, is
        the sole responsibility of the person who posed such User Contribution.
        By posting any User Contribution, you represent and warrant that: You
        are not a minor. You represent that you own (or have all rights
        necessary to grant H.U.M.V. the license above to) all the User
        Contributions you may post, and that H.U.M.V. will not need to obtain
        licenses from any third-party or pay royalties to any third-party to use
        such User Contribution(s). All of your User Contributions do and will
        comply with these Terms and any content standard stated herein.
        Monitoring and Enforcement: We do not undertake to review all User
        Contributions before they are posted have and cannot ensure prompt
        removal of objectionable material after it has been posted. Accordingly,
        we assume no liability or responsibility to anyone for any action or
        inaction regarding transmissions, communications, or content provided by
        any user or third-party. We have no liability for the performance or
        nonperformance of the activities described in this section. However, we
        reserve the right to: (i) remove or refuse to post any User Contribution
        for any or no reason in our sole discretion, (ii) take any action with
        respect to any User Contribution that we deem necessary or appropriate
        in our sole discretion, (iii) Disclose your identity or other
        information about you to any third party who claims that material posted
        by you violates their rights, including their intellectual property
        rights or their right to privacy, and (iv) take appropriate legal action
        for any illegal or unauthorized use of the Services. 12. THIRD PARTY
        INTELLECTUAL PROPERTY INFRINGEMENT: H.U.M.V. respects the intellectual
        property of others, and we ask our users to do the same. Each user is
        responsible for ensuring that their User Contribution does not infringe
        any third-party’s right or other intellectual property rights. If you
        believe that the Services or any User Contribution contains elements
        that infringe or misappropriate your copyrights or other intellectual
        property rights (or the intellectual property rights of others), please
        submit written notice to us at info@thehumv.com in accordance with this
        section. 13. THIRD PARTY PRODUCTS: Third-party services, content,
        information, and products may be made available by H.U.M.V. on or
        through the Website (“Third-Party Products”). Third-Party Products are
        provided solely for the convenience of our users and H.U.M.V. makes no
        representations or warranties regarding any Third-Party Products and
        takes no responsibility and assumes no liability for any Third-Party
        Products. Third-Party Products are subject to the applicable terms and
        policies of the third-parties that offer them. 14. HYPERLINKS: Links
        from the Website: The Website may contain links to websites operated by
        other parties. H.U.M.V. provides these links to other websites as a
        convenience and use of these sites is at your own risk. The linked sites
        are not under the control of H.U.M.V., and H.U.M.V. is not responsible
        for the content available on the other sites. Such links do not imply
        H.U.M.V.’s endorsement of information or material on any other site and
        H.U.M.V. disclaims all liability regarding your access to and use of
        such linked websites. Links to the Website: You may not place a link to
        the Website on another website without our prior written consent and,
        without limiting the foregoing, you must adhere to H.U.M.V.’s linking
        policy as follows: (i) the appearance, position and other aspects of the
        link may not be such as to damage or dilute the goodwill associated with
        H.U.M.V. and/or its licensors’ names and trademarks, (ii) the
        appearance, position and other attributes of the link may not create the
        false appearance that your organization or entity is sponsored by,
        affiliated with, or associated with H.U.M.V., (iii) when selected by a
        user, the link must display the Website on full-screen and not within a
        frame on the linking site, and (iv) H.U.M.V. reserves the right to
        revoke its consent to the link at any time and in its sole discretion.
        15. DISCLAIMER OF WARRANTIES: The services are provided on an as is and
        as available basis for your use, without warranties of any kind, either
        express or implied, including without limitation, warranties of
        satisfactory quality, merchantability, fitness for a particular purpose,
        title, non-infringement, and those arising from course of dealing or
        usage of trade. H.U.M.V. makes no warranty as to the quality, accuracy,
        completeness, or reliability of any content available through, or the
        performance of, the services. You are responsible for verifying any
        information before relying on it. Use of the services is at your sole
        risk. H.U.M.V. does not warrant that you will be able to access or use
        services at the times or locations of your choosing; that the operation
        of the services will be uninterrupted or error-free; that defects will
        be corrected; or that the services are free of viruses or other harmful
        components. H.U.M.V. provides no warranty or guaranty of any kind that
        the services will meet your expectations or requirements. Because some
        states or jurisdictions do not allow the disclaimer of implied
        warranties, the foregoing disclaimers may not apply to you. 16. BREACH
        OF THESE TERMS: You are responsible for compensating the following
        persons in full for any losses or damage they suffer which is caused by
        your breach of these Terms: H.U.M.V. and its parents, subsidiaries, and
        affiliated companies, and all of their respective past and present
        officers, directors, employees, agents, and representatives
        (collectively, the “Released Parties”). 17. LIMITATION OF LIABILITY;
        SOLE AND EXCLUSIVE REMEDY: To the maximum extent permitted by law, the
        released parties are not responsible and will have no obligation or
        liability resulting from (i) incorrect or incomplete information,
        whether caused by you or otherwise, technical errors, or by any of the
        equipment or programming associated with or utilized by H.U.M.V.; (ii)
        technical failures of any kind, including, but not limited to outages,
        malfunctions, interruptions, or disconnections in phone lines or network
        hardware or software or scheduled or unscheduled maintenance; (iii) the
        use or the inability to use the services; (iv) unauthorized access to or
        alteration of your transmissions or data; (v) statements or conduct of
        any third party on the website; (vi) any other matter relating to the
        services; (vi) the cost of procuring substitute goods, services or
        technology; or (vii) any indirect, incidental, consequential, or special
        or exemplary damages arising out of or in any way connected with access
        to or use of the services, even if H.U.M.V. and/or its affiliates have
        been advised of the possibility of such damages. To the full extent
        permissible under applicable law, H.U.M.V.’s aggregate liability to you
        or any third party in any circumstance shall not exceed one hundred
        dollars ($100). You acknowledge and agree that your sole remedy for any
        breach of these terms and any problems or dissatisfaction with the
        website or any portion of the software is to terminate your account and
        discontinue the use of the website and the software. Because some
        jurisdictions do not allow the exclusion or the limitation of liability
        for consequential or incidental damages, in such jurisdictions, the
        liability of H.U.M.V. and its affiliates hereunder shall be limited to
        the fullest extent permitted by law. 18. BINDING ARBITRATION, NO CLASS
        ACTION: Please read this section carefully. you understand and agree
        that you waive your right to sue or go to court to assert or defend your
        rights connected with these Messaging Terms. Mandatory Arbitration of
        Disputes: We each agree that any dispute, claim, or controversy arising
        out of or relating to these Terms or the breach, termination,
        enforcement, interpretation, or validity thereof or the use of the
        Services, including the determination of the scope or applicability of
        this arbitration provision, (each, a “Claim”) will be resolved solely by
        binding, individual arbitration and not in a class, representative or
        consolidated action or proceeding. You and H.U.M.V. agree that the U.S.
        Federal Arbitration Act (or equivalent laws in the jurisdiction in which
        the H.U.M.V. entity that you have contracted with is incorporated)
        governs the interpretation and enforcement of these Terms and that this
        arbitration provision shall survive termination of these Terms. Class
        Action Waiver: You and H.U.M.V. each agree that either of us may bring
        claims against the other only in an individual capacity, and not as a
        plaintiff or class member in any purported class or representative
        proceeding. Further, if the parties’ dispute is resolved through
        arbitration, neither JAMS nor the arbitrator may consolidate another
        person’s claims with your claims or otherwise preside over any form of a
        representative or class proceeding for any purpose. If this specific
        provision is found to be unenforceable, then the entirety of
        this Section 18 shall be null and void. Limited Remedies: Additionally,
        except as may be provided for below, or prohibited by law, we each agree
        that, for any dispute, claim, or controversy arising out of or relating
        to these Terms or the breach, termination, enforcement, interpretation,
        or validity thereof or the use of the Services, our remedies are limited
        to a claim for money damages (if any) and we each irrevocably waive any
        right to seek injunctive or equitable relief. Exceptions and Opt-out:
        Notwithstanding, we each retain the right to: seek to resolve a Claim in
        small claims court if it qualifies; and seek injunctive or other
        equitable relief from a court of competent jurisdiction to prevent (or
        enjoin) the infringement or misappropriation of our respective
        intellectual property rights.   19. CONTRACTING ENTITY, GOVERNING LAW;
        JURISDICTION: Users of the www.thehumv.com website are contracting with
        H.U.M.V., LLC. Users of the www.thehumv.comwebsite are contracting with
        H.U.M.V., LLC If you contracted with H.U.M.V., LLC all matters relating
        to the Services and/or Terms of Use applicable to www.thehumv.com, and
        any Claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or Claims), shall be governed by and construed
        in accordance with United States federal law and the laws of the State
        of Texas, without giving effect to the conflict of laws rules thereof.
        Subject to the arbitration provision and class action waiver in the
        Terms of Use applicable to www.thehumv.com, if you contract with
        H.U.M.V., LLC., exclusive jurisdiction for all Claims that are not
        required to be arbitrated will be in the state and federal courts
        located in Wilmington, Delaware, United States and you consent to the
        jurisdiction of those courts. If you contracted with H.U.M.V., LLC all
        matters relating to the Services and/or these Terms, and any dispute or
        Claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or Claims), shall be governed by and construed
        in accordance with the laws of England and Wales. Subject to the
        arbitration provision and class action waiver in these Terms, if you
        contract with H.U.M.V., LLC. exclusive jurisdiction for all Claims will
        be in Texas courts and you consent to the jurisdiction of those courts.
        20. LIMITATION ON TIME TO FILE CLAIMS: Any cause of action or claim you
        may have arising out of or relating to these terms or the services must
        be commenced within one (1) year after the cause of action or claim
        accrues; otherwise, such cause of action or claim is permanently barred.
        21. GENERAL: Relationship of Parties: You agree that no joint venture,
        partnership, employment, or agency relationship exists between you and
        H.U.M.V. because of these Terms or your use of the Services.
        Assignment: H.U.M.V. may assign these Terms, in whole or in part, to any
        person or entity at any time with or without your consent. You may not
        assign these Terms without H.U.M.V. 's prior written consent, and any
        unauthorized assignment by you shall be null and void ab initio.
        Notices: All notices given by you or required under these Terms shall be
        in writing and addressed to: H.U.M.V., LLC. 409 West Front St. suite
        100-286 Hutto, TX 78634 Equitable Remedies: You hereby agree that
        H.U.M.V. would be irreparably damaged if the terms of these Terms were
        not specifically enforced, and therefore you agree that we shall be
        entitled, without bond, other security, or proof of damages, to
        appropriate equitable remedies with respect to breaches of these Terms,
        in addition to such other remedies as we may otherwise have available to
        us under applicable laws. Force Majeure: Neither you or H.U.M.V. will be
        liable for any failure or delay in performance under these for causes
        beyond the other party’s reasonable control and not caused by that
        party’s fault, or negligence, including, but not limited to, “acts of
        God”, acts of government, flood, fire, civil unrest, acts of terror,
        strikes or other labor problems, computer attacks or malicious acts,
        such as attacks on or through the internet, any internet service
        provider, telecommunications or hosting facility, but in each case, only
        if and to the extent that the non-performing party is without fault in
        causing such failure or delay, and the failure or delay could not have
        been prevented by reasonable precautions and measures and cannot
        reasonably be circumvented by the non-performing party through the use
        of commercially reasonable, alternate sources. Waiver and
        Severability: Our failure to enforce any provision of these Terms shall
        in no way be construed to be a present or future waiver of such
        provision, nor in any way affect the right of any party to enforce each
        such provision thereafter. The express waiver by us of any provision,
        condition or requirement of these Terms shall not constitute a waiver of
        any future obligation to comply with such provision, condition or
        requirement. If any of the provisions of these Terms is found to be
        invalid or unenforceable, then that provision will be construed in a
        manner consistent with applicable laws to reflect, as nearly as
        possible, the original intentions of the parties, and the remaining
        provisions shall remain in full force and effect. Entire
        Agreement: These Terms, including Official Rules and Experience Rules,
        as well as any other the documents, rules or policies expressly
        incorporated by reference, constitutes the entire agreement between you
        and us with respect to the Services supersede all prior and
        contemporaneous understandings, agreements, representations, and
        warranties, both written and oral, regarding the Services.
      </p>
      <div className="mt-6">
        <NavLink
          to="/donate"
          className={`w-fit mx-auto min-w-max text-white font-semibold text-md px-3 py-2 rounded-lg bg-green-600 cursor-pointer`}>
          Back to Donation Page
        </NavLink>
      </div>
    </div>
  );
}
