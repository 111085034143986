import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import veteran1 from "../../assets/images/veteran-1.jpg";
import veteran2 from "../../assets/images/veteran-2.jpg";

export default function RightSideBar() {
  return (
    <aside className="max-w-[600px] mx-auto xl:mx-0 xl:max-w-[500px] xl:w-full grid gap-5 overflow-hidden max-h-[760px] scroll-bar scroll-bar-r p-4 bg-zinc-800/50 rounded-lg">
      <div className="pb-4">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper w-[300px] h-[400px] mb-8">
          <SwiperSlide>
            <img src={veteran1} alt="veteran" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={veteran2} alt="veteran" />
          </SwiperSlide>
        </Swiper>

        <div className="">
          <p className="text-gray-50 text-md text-left max-h-[260px] overflow-y-auto scroll-bar scroll-bar-r leading-7">
            Lee Rowland was born in Hampton, Virginia and enlisted in the US
            Army in 1985 as a 44B (Metalworker) and worked his way to 44E
            (Machinist). Lee climbed the ranks to the level of E-6 Promotable
            and won multiple awards including Non-Commission Officer of the
            year, Basic Non-Commission Officer Honor Graduate, and Sergeant
            Morales Club Member. Lee was selected for Warrant Officer Candidate
            School, commissioned as a Warrant Officer One-Allied Trades
            Technician – 914A in 1996, and was awarded Honor graduate for both
            Basic and Advance Course. After 25 years of exemplary service to our
            country, Lee retried as a Chief Warrant Officer 4 (Senior Logistics
            Officer). During his 25-year career, Lee served with the 1st Cavalry
            Division, the 3rd Infantry Division, the 25th Infantry Division, and
            the 4th Sustainment Brigade. He is A Veteran of Desert Storm, OIF
            and OEF, Recipient of 2 Bronze Stars and numerous other awards. Lee
            is married to Kim (Holt) Rowland, 3 children, and 4 grandchildren.
            Upon retiring from the military, Lee started work with Frito Lay as
            a maintenance engineer, and worked his way up to Zone Operations
            Manager, Warehouse Manager and ultimately Senior Operations
            Resource. Lee has been Outbound Sort Manager for FedEx for the last
            3 years. Additionally, Lee is an entrepreneur and owns a food truck.
            Lee Rowland is our Veteran of the Month because of his consistent
            hard work, and his noquit and can-do attitude. He has a special
            place in his heart for our country and especially the men and women
            that have and continue to put their lives on the line to protect
            this country and our way of life. H.U.M.V. is grateful and thankful
            to have Lee in our corner and he has been instrumental in guidance
            and assistance in H.U.M.V. Lee being our 1 st ever Veteran of the
            Month is an honor for H.U.M.V. and we felt it important for our
            followers to get to know him a little better.
          </p>
        </div>
      </div>
    </aside>
  );
}
