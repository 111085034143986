import React from "react";
import { createPortal } from "react-dom";
import Fade from "../fade/fade";

export default function Modal(props) {
  const { open, toggleModal, title, content, actions } = props;

  return createPortal(
    <Fade condition={open}>
      <div
        onClick={toggleModal}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 z-50">
        <div
          className="bg-white p-8 rounded-3xl transform transition-transform duration-300 min-w-[30%] max-w-[80%]"
          onClick={(e) => e.stopPropagation()}>
          {title}
          {content}
          {actions}
        </div>
      </div>
    </Fade>,
    document.body
  );
}
